import { Injectable, signal } from '@angular/core';
import { KeycloakService, KeycloakEvent, KeycloakEventType } from 'keycloak-angular';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, map, retry } from 'rxjs';
import { Guid } from 'guid-typescript';

import { environment } from '@environments/environment';
import { UserService } from './user.service';

import { IPushRegistrationData } from '@core/interfaces/notifications.interface';
import { getMessaging, getToken } from '@firebase/messaging';

@Injectable({
  providedIn: 'root',
})
export class AuthSsoService {
  private _tokenParsed: Keycloak.KeycloakTokenParsed | undefined = {};
  private _httpClientWithoutInterceptor: HttpClient;
  private _updateTokenInterval: any;

  private static readonly tokenForcedRefreshTimeIntervalMs: number = 240000;
  private static readonly clientName = 'cs_chargingservices_frontend';

  pushToken: string = '';
  fdGwToken: string = '';
  fmpToken: string = ''; // In use only for guided tour in navbar as it accepts only fmp token currently, should be removed afterwards.

  fdGWTokenSignal = signal<string>('');
  isLoggedIn = signal<boolean>(false);

  constructor(
    httpBackend: HttpBackend,
    private keycloakService: KeycloakService,
    private userService: UserService,
  ) {
    this._httpClientWithoutInterceptor = new HttpClient(httpBackend);
  }

  /** Handling different Keycloak events
   ** OnAuthError = 0,
   ** OnAuthLogout = 1,
   ** OnAuthRefreshError = 2,
   ** OnAuthRefreshSuccess = 3,
   ** OnAuthSuccess = 4,
   ** OnReady = 5,
   ** OnTokenExpired = 6
   */
  public handleKeycloakEvent(event: KeycloakEvent): void {
    /** -----------------------onReady-------------------------- */
    if (event.type === KeycloakEventType.OnReady) {
      console.log('[authService] Keycloak event: OnReady');
    }

    /** ---------------------OnAuthSuccess------------------------ */
    if (event.type === KeycloakEventType.OnAuthSuccess) {
      console.log('[authService] Keycloak event onAuthSuccess');
      this.onSuccessfulKeycloakLogin();
    }

    /** ---------------------OnTokenExpired--------------------------- */
    if (event.type === KeycloakEventType.OnTokenExpired) {
      console.log('[authService] Keycloak event: onTokenExpired');
      this.refreshToken().then(() => {
        console.log('[authService] token refreshed on expiry.');
      });
    }

    /** ---------------------.OnAuthLogout------------------------------ */
    if (event.type === KeycloakEventType.OnAuthLogout) {
      console.log('[authService] Keycloak event: OnAuthLogout', event.args);
      this.logout('KeycloakEventType.OnAuthLogout').then(() => {
        console.log('[authService] logout completed');
      });
    }

    /** ---------------------OnAuthError---------------------------------- */
    if (event.type === KeycloakEventType.OnAuthError) {
      console.error('[authService] Keycloak event: OnAuthError');
      this.onKeycloakAuthError(event);
    }

    /** ---------------------OnAuthRefreshSuccess------------------------------ */
    if (event.type === KeycloakEventType.OnAuthRefreshSuccess) {
      console.log('[authService] Keycloak event: OnAuthRefreshSuccess');
    }

    /** ---------------------OnAuthRefreshError------------------------------ */
    if (event.type === KeycloakEventType.OnAuthRefreshError) {
      console.error('[authService] Keycloak event: OnAuthRefreshError', event.args);
    }
  }

  private onKeycloakAuthError(event: KeycloakEvent): void {
    console.log('[authService] Keycloak auth error');
    //TODO: make this work with a modal of our choice, if needed, we start by not logging out.
    // const options: any = {
    //   title: 'EttFelUppstodVidInloggningen',
    //   content: 'EttTillfälligtFelUppstod_VäntaEnStundOchFörsökIgen_OmProblemet',
    //   confirmButton: {
    //     text: 'Ok',
    //     class: 'btn-primary',
    //     icon: '',
    //   },
    // };
    // const modalRef = this.getModal.open(ConfirmModalComponent);
    // modalRef.componentInstance.options = options;
    // modalRef.result.then((): void => {
    //   // This error is mostly triggered when we managed to authenticate, get a keycloak token but the fetching from iam-facade then failed.
    //   // eslint-disable-next-line @typescript-eslint/no-empty-function
    //   window.document.location.reload();
    // });
  }

  /**
   * Refresh the Keycloak token and create a new FMP token
   *
   * @returns boolean flag indicating if refreshToken and FMP token creation is completed successfully or not
   */
  public async refreshToken(): Promise<boolean> {
    let tokenRefreshed = false;

    try {
      tokenRefreshed = await this.keycloakService.updateToken(-1); // -1: to force refresh the token
      this._tokenParsed = this.keycloakService.getKeycloakInstance().tokenParsed;
    } catch (error) {
      // await this.logout(`Error in refreshing the token: ${error}`);
      return false;
    }

    if (!tokenRefreshed) {
      return false;
    }

    this.fdGwToken = await this.keycloakService.getToken();

    if (this.fdGwToken) {
      return true;
    }

    return false;

    // const fmpTokenCreated = await this.createFmpTokenFromFdGwToken(true);
    // this.refreshFMPTokenInNavbar(); // TODO: enable this when you start using the notifications component, navbar needs it for that.

    // return fmpTokenCreated;
  }

  dispatchNavbarTokens() {
    // dispatch navbar event when token is refreshed
    document.dispatchEvent(
      new CustomEvent('navbar_RefreshToken', {
        detail: this.fdGwToken,
      }),
    );

    document.dispatchEvent(
      new CustomEvent('navbar_UpdateToken', {
        detail: this.fdGwToken,
      }),
    );
    document.dispatchEvent(new CustomEvent('navbar_UpdateFmpToken', { detail: this.fmpToken }));
  }

  // _______________________________FMP TOKEN IN NAVBAR WHEN NEEDED ______________________________
  // TODO: enable this when you start using the notifications component, navbar needs it for that.
  // private refreshFMPTokenInNavbar(): void {
  //   document.dispatchEvent(
  //     new CustomEvent('navbar_RefreshFMPToken', {
  //       detail: this.fmpToken,
  //     })
  //   );
  //   console.log('[authService] - refresh FMP token in navbar');
  // }

  /**
   * boolean flag indicating if Keycloak token is expired or not
   *
   * @returns boolean flag indicating if Keycloak token is expired or not
   * @description If keycloak is not initialized then this function will return false!
   */
  public isKeycloakTokenExpired(): boolean {
    if (this._tokenParsed == null) {
      return false;
    }
    try {
      const tokenExpiryTimeEpoch = this._tokenParsed.exp || -1;
      const expireInSeconds = Math.round(tokenExpiryTimeEpoch - new Date().getTime() / 1000);
      return expireInSeconds <= 0 ? true : false;
    } catch (error) {
      console.error('[authService] failed to get token expiry.', error);
      return false;
    }
  }

  /**
   * logout the user
   *
   * @param reason - logout reason
   */
  public async logout(reason: string, localhost = false): Promise<void> {
    console.log('[authService] logout - reason: ' + reason);
    this.notificationDeregistration().subscribe({
      error: (err) => {
        console.log(err);
      },
    });
    this.isLoggedIn.set(false);
    this.userService.clearUser();
    window.localStorage.removeItem('tokenRegistered');
    window.sessionStorage.clear();
    await this.keycloakService.logout(localhost ? 'http://localhost:4200/' : environment.logoutUrl);
  }

  /**
   * method invoked after successful keycloak login
   */
  private onSuccessfulKeycloakLogin(): void {
    this._tokenParsed = this.keycloakService.getKeycloakInstance().tokenParsed;
    this.keycloakService.getToken().then((token) => {
      this.fdGwToken = token;
      this.isLoggedIn.set(true);
      this.fdGWTokenSignal.set(token);
      this.userService.decodeTokenAndSetUserData(this.fdGwToken).subscribe();
      this.registerFirebasePushToken();
    });

    this.createFmpTokenFromFdGwToken(false).then((token: any) => {
      if (token) {
        this.scheduleTokenRefresh();
      }
      this.dispatchNavbarTokens();
    });
  }

  /**
   * Schedule the Refresh token timer
   */
  private scheduleTokenRefresh(): void {
    if (this._updateTokenInterval) {
      clearInterval(this._updateTokenInterval);
    }
    this._updateTokenInterval = setInterval(() => this.refreshToken(), AuthSsoService.tokenForcedRefreshTimeIntervalMs);
  }

  private async createFmpTokenFromFdGwToken(duringRefresh: boolean): Promise<boolean> {
    const fdGwToken = await this.keycloakService.getToken();

    if (fdGwToken == null) {
      this.isLoggedIn.set(false);
      return false;
    }

    let fmpToken = null;
    try {
      fmpToken = await this.fmpTokenHttpRequest(fdGwToken);
    } catch (error) {
      const reason = JSON.stringify(error);
      await this.handleWhenFmpTokenCreationIsFailed(reason);
      return false;
    }

    if (fmpToken) {
      console.log('[authService] FMP token is created');
      await this.onSuccessfulFmpTokenCreation(fmpToken, duringRefresh);
      return true;
    } else {
      const reason = 'Invalid token';
      await this.handleWhenFmpTokenCreationIsFailed(reason);
      return false;
    }
  }

  private async onSuccessfulFmpTokenCreation(fmpToken: string, duringRefresh: boolean): Promise<void> {
    this.fmpToken = fmpToken;

    if (!duringRefresh) {
      this.isLoggedIn.set(true);
    }
  }

  private async handleWhenFmpTokenCreationIsFailed(reason: string): Promise<void> {
    console.error('[authService] Failed to create FMP token. Reason: ' + reason);
    // await this.logout('Failed to create FMP token. Reason: ' + reason);
  }

  private fmpTokenHttpRequest(fdGwToken: string): Promise<string> {
    const observable = this._httpClientWithoutInterceptor
      .post<FmpTokenResponse>(environment.authApi + 'token', null, {
        headers: {
          authorization: 'Bearer ' + fdGwToken,
          'x-client': AuthSsoService.clientName,
        },
      })
      .pipe(
        retry(2),
        map((response) => response.access_token),
      );
    return lastValueFrom(observable);
  }

  registerFirebasePushToken(): void {
    const messaging = getMessaging();

    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.pushToken = currentToken;
          const pushData = { pushToken: currentToken, pushTokenProvider: environment.pushTokenProvider };

          if (!window.localStorage.getItem('tokenRegistered')) {
            this.notificationRegistration(pushData).subscribe({
              error: (err) => {
                console.log(err);
              },
            });
            window.localStorage.setItem('tokenRegistered', 'true');
          }
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  notificationRegistration(pushData: IPushRegistrationData) {
    const headers = this.getHeaders();
    const url = environment.pushPlatform + `v1/registration/${environment.appId}/${this.pushToken}`;
    return this._httpClientWithoutInterceptor.post(url, pushData, { headers: headers });
  }

  notificationDeregistration() {
    const url = environment.pushPlatform + `v1/registration/${environment.appId}/${this.pushToken}`;
    const headers = this.getHeaders();
    return this._httpClientWithoutInterceptor.delete(url, { headers: headers });
  }

  getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `Bearer ${this.fdGwToken}`);
    headers = headers.set('x-client', 'cs_chargingservices_frontend');
    headers = headers.set('X-Correlation-ID', Guid.create().toString());
    return headers;
  }
}

/**  Logout event payload that is sent to Auth API */
export interface LogoutEventPayload {
  sessionState: string;
  clientId: string;
  reason: string;
}

export interface FmpTokenResponse {
  access_token: string;
}
